<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">订货单 - 已完成</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-date-picker v-model="date" size="small" type="daterange" unlink-panels
                      :clearable="false" class="el-date-picker family"
                      style="width: 240px !important;margin-right: 10px"
                      range-separator="/" format="yyyy-MM-dd" value-format="timestamp" :editable="false"/>
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称"
                @keyup.enter.native="selectOrder" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-input class="family" size="small" v-model.trim="query.value"
                @keyup.enter.native="selectOrder" clearable maxlength="20"
                style="width: 300px !important;margin-right: 10px">
        <el-select class="family" size="small" slot="prepend" v-model="query.type"
                   @change="typeChange" style="width: 75px">
          <el-option value="isbn" label="书号"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          <el-option value="title" label="书名"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        </el-select>
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectOrder">查询</el-button>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>

        <span class="span" style="color: #606266;margin-left: 30px">订数 </span>
        <span class="span" style="color: #409EFF">{{countnum}}</span>
        <span class="span" style="color: #606266;margin-left: 30px">缺货 </span>
        <span class="span" style="color: #409EFF">{{countless}}</span>
        <span class="span" style="color: #606266;margin-left: 30px">书款 </span>
        <span class="span" style="color: #409EFF">{{parseFloat(countprice).toFixed(2)}}</span>
        <span class="span" style="color: #606266;margin-left: 30px">实付现款 </span>
        <span class="span" style="color: #EB6100">{{parseFloat(countpay).toFixed(2)}}</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="orders" :style="{width: '834px'}"
              :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}">
      <el-table-column width="80" label="订单号" prop="id" :resizable="false" :formatter="idFormat"/>
      <el-table-column label="订货时间" prop="time" width="100" :resizable="false" :formatter="timeFormat"/>
      <el-table-column label="供货商" width="250" :resizable="false">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <div style="float:left">
              <span style="margin-right: 5px">{{scope.row.seller.name}}</span>
              <svg v-if="scope.row.seller.qq!==undefined" class="icon" aria-hidden="true"
                   @click="openQQ(scope.row.seller.qq)"
                   style="cursor:pointer;font-size: 14px;margin-left: -2px;margin-right: 2px">
                <use xlink:href="#icon-qq1"></use>
              </svg>
              <el-tag type="warning">{{payFormat(scope.row.seller.pay)}}</el-tag>
              <el-tag v-if="scope.row.getBook" type="primary">代提</el-tag>
            </div>
            <span v-if="scope.row.comment!==undefined && scope.row.comment!==''"
                  class="comment">{{scope.row.comment}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订数" prop="orderNum" width="60" :resizable="false"/>
      <el-table-column label="缺货" prop="lessNum" width="60" :resizable="false"
                       :formatter="lessNumFormat"/>
      <el-table-column label="书款" width="75" prop="orderPrice" :resizable="false">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <span v-if="scope.row.orderOriPrice>scope.row.orderPrice"
                  style="color: #C0C4CC;text-decoration: line-through">
                {{orderPriceFormat(scope.row.orderOriPrice)}}
            </span>
            <span>{{orderPriceFormat(scope.row.orderPrice)}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="付款信息" width="145" :resizable="false">
        <template slot-scope="scope">
          <div v-if="scope.row.account">
            <div v-if="scope.row.cashPrice>0">
              <el-tag type="warning">现款</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.cashPrice)}}</span>
              <el-tag v-if="scope.row.payId!==undefined" type="success"
                      style="margin-left: 5px;cursor:pointer" @click="openImg(scope.row)">已代付
              </el-tag>
            </div>
            <div v-if="scope.row.accountPrice>0">
              <el-tag type="warning">{{scope.row.seller.pay==='0'?'抵扣':'记账'}}</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.accountPrice)}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="明细" width="63" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-button class="buttonTable" size="mini" type="primary" icon="el-icon-search"
                       @click="getDetail(scope.row)"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <el-dialog :visible.sync="visibleImg" custom-class="dialogImg" append-to-body
               :close-on-click-modal="true">
      <el-image style="width: 100%; height: 100%" :src="payImg" fit="cover">
        <div slot="placeholder">
          <i class="el-icon-loading"></i>
          <span style="font-size: 14px;color: #909399">加载中…</span>
        </div>
        <div slot="error">
          <span style="font-size: 14px;color: #909399">付款凭证暂未生成，请稍后查看…</span>
        </div>
      </el-image>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created () {
      this.id = sessionStorage.getItem('orderbackId')
      if (this.id !== null) {
        sessionStorage.clear()
        this.getOrder(this.id)
      } else {
        this.selectOrder()
      }
    },
    data () {
      return {
        query: {
          begin: '',
          end: '',
          name: '',
          type: 'isbn',
          value: '',
          page: 1
        },
        date: [Date.now() - 29 * 86400000, Date.now()],
        orders: [],
        total: 0,
        countnum: 0,
        countless: 0,
        countprice: 0,
        countpay: 0,
        id: null,
        visibleImg: false,
        payImg: ''
      }
    },
    methods: {
      idFormat (row) {
        return '#' + (Array(7).join('0') + row.id).slice(-7)
      },
      timeFormat (row) {
        const date = new Date(row.time)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      payFormat (value) {
        if (value === '0') {
          return '日结'
        } else if (value === '1') {
          return '月结'
        } else if (value === '2') {
          return '预付'
        }
      },
      lessNumFormat (row) {
        if (row.lessNum > 0) {
          return row.lessNum
        }
      },
      orderPriceFormat (value) {
        if (value > 0) {
          return parseFloat(value).toFixed(2)
        }
      },
      priceFormat (value) {
        return parseFloat(value).toFixed(2)
      },

      typeChange () {
        this.query.value = ''
      },
      selectOrder () {
        this.query.page = 1
        this.getOrderList()
      },
      pageChange (page) {
        this.query.page = page
        this.getOrderList()
      },
      async openImg (row) {
        this.payImg = this.imageURL + row.payId + '.png?' + new Date()
        this.visibleImg = true
      },

      async getOrder (id) {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getComplateOrder',
            { params: { id: id } })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.orders = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.orderNum
          this.countless = res.data.count.lessNum
          this.countprice = res.data.count.orderPrice
          this.countpay = res.data.count.cashPrice
          this.orders = res.data.data

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.orders = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async getOrderList () {
        this.query.begin = this.date[0]
        this.query.end = this.date[1]
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getComplateOrderList',
            { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.orders = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.orderNum
          this.countless = res.data.count.lessNum
          this.countprice = res.data.count.orderPrice
          this.countpay = res.data.count.cashPrice
          this.orders = res.data.data

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.orders = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      getDetail (row) {
        sessionStorage.setItem('id', row.id)
        sessionStorage.setItem('type', this.query.type)
        sessionStorage.setItem('value', this.query.value)
        sessionStorage.setItem('name', row.seller.name)
        const route = this.$router.resolve({ name: 'detail' })
        window.open(route.href, '_blank')
      },
      async openQQ (qq) {
        if (qq !== undefined) {
          location.href = 'tencent://message/?uin=' + qq
          try {
            await this.$http.get('openQQ')
          } catch (e) {
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  /deep/ .buttonTableText {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText1 {
    width: 12px !important;
    height: 12px !important;
    font-size: 12px;
    padding: 0;
    color: #F56C6C;
  }

  /deep/ .buttonTableText2 {
    width: 12px !important;
    height: 12px !important;
    font-size: 12px;
    padding: 0;
    color: #67C23A;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  .comment {
    color: #EB6100;
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.91666);
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .dialogImg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 655px;
    height: 525px;
  }
</style>
